@use "./color" as *

@forward "./main"

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&display=swap')
@import url('https://cdn.iconmonstr.com/1.3.0/css/iconmonstr-iconic-font.min.css')


// *::-webkit-scrollbar
//   width: 16px

// *::-webkit-scrollbar-track
//   // border-radius: 8px
//   background-color: $gray-200

// *::-webkit-scrollbar-thumb
//   height: 56px
//   border-radius: 8px
//   border: 4px solid transparent
//   background-clip: content-box
//   background-color: $gray-400

// *::-webkit-scrollbar-thumb:hover
//   background-color: $gray-500

.scroll::-webkit-scrollbar-track
  -webkit-box-shadow: none
  background-color: $gray-50

.scroll::-webkit-scrollbar
  width: 5px
  background-color: $gray-400

.scroll::-webkit-scrollbar-thumb
  background-color: $gray-400
  border-radius: 5px