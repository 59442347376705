$gray-50: #F7FAFC
$gray-100: #EDF2F7
$gray-200: #E2E8F0
$gray-300: #CBD5E0
$gray-400: #A0AEC0
$gray-500: #718096
$gray-600: #4A5568
$gray-700: #2D3748
$gray-800: #1A202C
$gray-900: #171923

$blue: #001689
$red: #ba0c2f

$red-500: #f8384b
$red-600: #e82f49

$blue-sky: #34B7F1

$green: #00BFA5