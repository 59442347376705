@use "./vars" as *
.main
  width: 100%
  padding: 0 0 0 $aside-width

.wrapper
  width: 100%
  display: grid
  grid-auto-columns: minmax(auto, 1280px)
  padding: $rem * 2
  justify-content: center

.info
  // height: $accordion-height + ($accordion-height/2)

.hero
  width: 100%
  // height: ($accordion-height * 7)
  background: white


